// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.store {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;

  }
  
  .yoyo-item {
    text-align: center;
  }
  
  .yoyo-item img {
    width: 150px;
    height: 150px;
    object-fit: cover;
  }
  
  .yoyo-item h2 {
    margin-top: 10px;
  }
  
  .member-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
  }
  
  .member-image:hover {
    transform: scale(1.1);
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/Store.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,SAAS;IACT,uBAAuB;;EAEzB;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,iBAAiB;EACnB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,iBAAiB;IACjB,sCAAsC;EACxC;;EAEA;IACE,qBAAqB;EACvB","sourcesContent":[".store {\n    display: flex;\n    flex-wrap: wrap;\n    gap: 30px;\n    justify-content: center;\n\n  }\n  \n  .yoyo-item {\n    text-align: center;\n  }\n  \n  .yoyo-item img {\n    width: 150px;\n    height: 150px;\n    object-fit: cover;\n  }\n  \n  .yoyo-item h2 {\n    margin-top: 10px;\n  }\n  \n  .member-image {\n    width: 150px;\n    height: 150px;\n    border-radius: 50%;\n    object-fit: cover;\n    transition: transform 0.3s ease-in-out;\n  }\n  \n  .member-image:hover {\n    transform: scale(1.1);\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
