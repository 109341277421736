// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
    text-align: center;
    padding: 10px 20px;
    background-color: #333;
    color: #fff;
  }
  
  .logo {
    font-size: 24px;
  }
  
  .nav-links {
    margin-top: 10px;
  }
  
  .nav-links a {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
    margin: 0 15px;
  }

  .cart-link a {
    margin: 0 10px;
    text-decoration: none;
    color: #fff;
    font-weight: bold;
  }
  
  .cart-link {
    margin-top: 10px;
    color: #fff;
  }
  
  /* Optional: Add hover effect */
  .nav-links a:hover {
    text-decoration: underline;
  }
  

`, "",{"version":3,"sources":["webpack://./src/components/Header.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,sBAAsB;IACtB,WAAW;EACb;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,WAAW;IACX,qBAAqB;IACrB,iBAAiB;IACjB,cAAc;EAChB;;EAEA;IACE,cAAc;IACd,qBAAqB;IACrB,WAAW;IACX,iBAAiB;EACnB;;EAEA;IACE,gBAAgB;IAChB,WAAW;EACb;;EAEA,+BAA+B;EAC/B;IACE,0BAA0B;EAC5B","sourcesContent":[".header {\n    text-align: center;\n    padding: 10px 20px;\n    background-color: #333;\n    color: #fff;\n  }\n  \n  .logo {\n    font-size: 24px;\n  }\n  \n  .nav-links {\n    margin-top: 10px;\n  }\n  \n  .nav-links a {\n    color: #fff;\n    text-decoration: none;\n    font-weight: bold;\n    margin: 0 15px;\n  }\n\n  .cart-link a {\n    margin: 0 10px;\n    text-decoration: none;\n    color: #fff;\n    font-weight: bold;\n  }\n  \n  .cart-link {\n    margin-top: 10px;\n    color: #fff;\n  }\n  \n  /* Optional: Add hover effect */\n  .nav-links a:hover {\n    text-decoration: underline;\n  }\n  \n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
