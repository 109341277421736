// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-container {
    text-align: center;
  }
  .top-image {
    width: 50%;
    max-height: 700px; /* Adjust according to your design */
    object-fit: cover;
  }
  .youtube-video {
    width: 70%;
    height: 500px;
    border: none;
    margin-top: 20px;
    margin-bottom: 100px;
  }
  
  @media only screen and (max-width: 600px) {
    .youtube-video {
      height: 300px;
    }
    .top-image {
        height: 300px;
      }
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/Home.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;EACpB;EACA;IACE,UAAU;IACV,iBAAiB,EAAE,oCAAoC;IACvD,iBAAiB;EACnB;EACA;IACE,UAAU;IACV,aAAa;IACb,YAAY;IACZ,gBAAgB;IAChB,oBAAoB;EACtB;;EAEA;IACE;MACE,aAAa;IACf;IACA;QACI,aAAa;MACf;EACJ","sourcesContent":[".home-container {\n    text-align: center;\n  }\n  .top-image {\n    width: 50%;\n    max-height: 700px; /* Adjust according to your design */\n    object-fit: cover;\n  }\n  .youtube-video {\n    width: 70%;\n    height: 500px;\n    border: none;\n    margin-top: 20px;\n    margin-bottom: 100px;\n  }\n  \n  @media only screen and (max-width: 600px) {\n    .youtube-video {\n      height: 300px;\n    }\n    .top-image {\n        height: 300px;\n      }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
