// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    display: grid;
    place-items: center;
    background-color: #333;
    color: #fff;
    padding: 10px;
  }
  
  .footer p {
    margin: 0;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Footer.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,WAAW;IACX,aAAa;EACf;;EAEA;IACE,SAAS;EACX","sourcesContent":[".footer {\n    display: grid;\n    place-items: center;\n    background-color: #333;\n    color: #fff;\n    padding: 10px;\n  }\n  \n  .footer p {\n    margin: 0;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
