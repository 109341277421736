import React from 'react';

const Stories = () => {
  return (
    <div>

      <h1>Stories</h1>
      <p>Stories Page Content</p>

    </div>
  );
}

export default Stories;