// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cart {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; /* or center if you want to vertically center items when there's extra vertical space */
    width: 100%;
    min-height: 100vh; /* adjust min-height as per your need */
    padding: 20px; /* optional: for some space around items */
  }
  
  /* .cart-item-image {
    width: 80px;
    height: 80px;
    object-fit: cover;
  } */
  
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  li {
    margin-bottom: 20px;
  }
  
  .total-cost {
    font-size: 1.5em;
    margin-top: 20px;
  }
  
  .cart-item {
    display: flex;
    align-items: center;
    gap: 15px; /* Space between image and texts */
    margin-bottom: 15px; /* Space between each cart item */
  }
  
  .cart-item-image {
    width: 80px; /* Adjust as per your requirement */
    height: 80px; /* Adjust as per your requirement */
  }
  
  /* Style for the text content within the cart item */
  .cart-item p {
    margin: 0;
  }
  
  .quantity-control {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .quantity-control button {
    padding: 5px 10px;
    cursor: pointer;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/Cart.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,2BAA2B,EAAE,uFAAuF;IACpH,WAAW;IACX,iBAAiB,EAAE,uCAAuC;IAC1D,aAAa,EAAE,0CAA0C;EAC3D;;EAEA;;;;KAIG;;EAEH;IACE,qBAAqB;IACrB,UAAU;EACZ;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;IAChB,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,SAAS,EAAE,kCAAkC;IAC7C,mBAAmB,EAAE,iCAAiC;EACxD;;EAEA;IACE,WAAW,EAAE,mCAAmC;IAChD,YAAY,EAAE,mCAAmC;EACnD;;EAEA,oDAAoD;EACpD;IACE,SAAS;EACX;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,QAAQ;EACV;;EAEA;IACE,iBAAiB;IACjB,eAAe;EACjB","sourcesContent":[".cart {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: flex-start; /* or center if you want to vertically center items when there's extra vertical space */\n    width: 100%;\n    min-height: 100vh; /* adjust min-height as per your need */\n    padding: 20px; /* optional: for some space around items */\n  }\n  \n  /* .cart-item-image {\n    width: 80px;\n    height: 80px;\n    object-fit: cover;\n  } */\n  \n  ul {\n    list-style-type: none;\n    padding: 0;\n  }\n  \n  li {\n    margin-bottom: 20px;\n  }\n  \n  .total-cost {\n    font-size: 1.5em;\n    margin-top: 20px;\n  }\n  \n  .cart-item {\n    display: flex;\n    align-items: center;\n    gap: 15px; /* Space between image and texts */\n    margin-bottom: 15px; /* Space between each cart item */\n  }\n  \n  .cart-item-image {\n    width: 80px; /* Adjust as per your requirement */\n    height: 80px; /* Adjust as per your requirement */\n  }\n  \n  /* Style for the text content within the cart item */\n  .cart-item p {\n    margin: 0;\n  }\n  \n  .quantity-control {\n    display: flex;\n    align-items: center;\n    gap: 5px;\n  }\n  \n  .quantity-control button {\n    padding: 5px 10px;\n    cursor: pointer;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
